import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function WorkingHours() {
  const {user,agent} = useSelector(state => state.user.userData)
  const crmData = (agent||user)?.detail?.crmData;
  
  return (
    <div id='hours' className="w-[600px] max-w-full flex flex-col gap-4 py-10">
        <h4>Chat with us:</h4>
        <p>Connect with one of our agents for assistance by calling {(agent||user)?.phone}</p>
        <div>Our operating hours are as follows</div>
        <ol>
          {crmData?.contact?.workingDays?.map((obj,i) => (
            <li key={i}>{obj?.day}: {obj?.timeRange?.join(' - ')}</li>
          ))}
            {/* <li>Monday to Friday: 07:00 - 19:00</li>
            <li>Weekends and Public holidays: 08:00 - 14:00</li> */}
        </ol>
        <div>
            For any queries on the latest travel updates and regulations, visit our <Link to='#'>Travel Info page</Link>
        </div>
    </div>
  )
}
