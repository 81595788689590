import React, { useEffect, useState } from "react";
import useOrders from "../../hooks/useOrders";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function OrderList() {
  const { getOrders } = useOrders();
  return (
    <div className="w-full flex flex-col mt-4 bg-[#F0F6FC] gap-3 p-1">
      {getOrders().map((order, index) => (
        <Order key={index} order={order} />
      ))}
    </div>
  );
}

export default OrderList;

const Order = ({ order }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="w-full flex  justify-between bg-white p-4 gap-3 ">
      <div className="flex flex-col md:flex-row justify-between flex-1">
        {order?.map(({ elements, width }, index) => (
          <div
            className={`w-full ${width || "flex-1"} ${
              !open && index !== 0 ? "hidden md:flex" : "flex"
            } flex-col ${open && "border-b pb-3"} mb-2 md:border-b-0 md:pb-0`}
            key={index}
          >
            {elements?.map((element, idx) => (
              <OrderContent element={element} key={idx} />
            ))}
          </div>
        ))}
      </div>
      <div className="flex flex-col justify-between items-center ">
        <Menu order={order} />
        <div className="flex md:hidden" onClick={() => setOpen(!open)}>
          {open ? (
            <img
              src="/IconChevronUp.svg"
              alt=""
              className="w-5 object-contain"
            />
          ) : (
            <img
              src="/IconChevronDown.svg"
              alt=""
              className="w-5 object-contain"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrderContent = ({ element }) => {
  if(element?.render)
    return element?.render();
  
  switch (element.type) {
    case "title":
      return <Title value={element.value} />;
    case "copy":
      return <Copy value={element.value} />;
    case "tag":
      return <Tag element={element} />;
    case "time":
      return <Time value={element?.value} />;
    case "status":
      return <Status value={element?.value} />;
    default:
      return <Text value={element.value} />;
  }
};

const Text = ({ value }) => {
  return <span className="text-sm mb-2">{value}</span>;
};

const Title = ({ value }) => {
  return <span className="text-sm text-[#6D7C94] mb-2">{value}</span>;
};

const Copy = ({ value }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(value);
      enqueueSnackbar("Copied to clipboard!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to copy to clipboard!", { variant: "error" });
    }
  };
  return (
    <div
      className="flex gap-1 items-center mb-2 cursor-pointer"
      onClick={handleCopy}
    >
      <span className="text-xs text-primary1">{value}</span>
      <img src="/IconCopy.svg" alt="" />
    </div>
  );
};

const Tag = ({ element }) => {
  return (
    <div className={`rounded-md `}>
      <span
        className={`${element?.color} ${element?.background} text-xs font-bold px-2 py-1 rounded-md ${element?.className}`}
      >
        {element?.value}
      </span>
    </div>
  );
};

const Time = ({ value }) => {
  return (
    <div className="flex gap-1 items-center mb-2 cursor-pointer">
      <img src="/IconClock1.svg" alt="" />
      <span className="text-xs">{value}</span>
    </div>
  );
};

const Status = ({ value }) => {
  return (
    <div className={`rounded-md mb-3`}>
      <span
        className={`bg-[#FDF3D7] text-[#6B5618] text-xs font-bold px-2 py-1 rounded-md `}
      >
        {value}
      </span>
    </div>
  );
};

const Menu = ({ order }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {ticketType} = useSelector(state => state.order);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (open) {
        setTimeout(() => {
          setOpen(false);
        }, 400);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const handleSelect = () => {
    navigate("/order/flight/ticket/detail");
  };

  const menu = () => {
    let menus = [
      {
        label: "View order",
        action: () => {},
        type: 'Flights',
      },
    "Cancel order", "Open order", "Refund"]
    switch(ticketType) {
      case 'Flights':
        let items = menus?.filter(obj => obj.type === 'Flights')
        return items
    }
  };

  return (
    <div className="cursor-pointer p-2 relative" onClick={() => setOpen(true)}>
      <img src="/IconMore.svg" alt="" className="h-5 object-contain" />
      {open && (
        <div className="absolute py-2 flex flex-col bg-white rounded-md right-6 top-4 shadow-md min-w-[200px]">
          {menu().map((item, index) => (
            <div
              key={index}
              onClick={() => handleSelect()}
              className="font-bold text-sm text-[#818083] px-2 py-2 hover:bg-[#818083]/10 z-20"
            >
              {item?.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
