import React from 'react'
import { useSelector } from 'react-redux'

export default function FooterContact() {
    const {user,agent} = useSelector(state => state.user.userData);
    const userData = (agent || user);

    const crmData = (agent || user)?.detail?.crmData;
    const contactExtra = JSON.parse(crmData?.contact?.other || '{}')

  return (
    !contactExtra?.contactDetail ? 
      <div className="flex flex-wrap gap-3">
          <p>{userData?.phone}</p>
          <p>{userData?.email}</p>
          <p>{userData?.detail?.address?.businessLocation}</p>
      </div>
    :
    <div dangerouslySetInnerHTML={{ __html: contactExtra?.contactDetail }} />
)
}
