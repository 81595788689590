import React, { useState } from 'react'
import TextInput from '../../../components/form/TextInput'
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import updateProfile from '../../../controllers/user/updateProfile';
import { setUser } from '../../../redux/reducers/userSlice';
import Button1 from '../../../components/form/Button1';
import Icon from '../../../components/HOC/Icon';
import CopyText from '../../../components/mini/CopyText';


export default function CustomDomain() {
    const { user } = useSelector((state) => state.user.userData);

    const [data, setData] = useState({
        agencyLogo: user?.detail?.agencyLogo || "",
        agencyName: user?.detail?.agencyName || "",
        // registeredBusinessName: user?.detail?.registeredBusinessName || "",
        agencyURL: user?.detail?.agencyURL || "",
        customDomain: user?.detail?.customDomain || "",
      });

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    async function handleSubmit(ev) {
        ev.preventDefault();

        const formData = new FormData();
        const modData = {customDomain: data?.customDomain};
        Object.entries(modData).map(([key, val]) => val && formData.append(key, val));

        setLoading(true);
        const res = await updateProfile(formData);
        setLoading(false);
        if (res.return) {
            enqueueSnackbar("Profile Updated", { variant: "success" });
            // console.log(res.data);
            if (res?.data) dispatch(setUser(res.data));
        } else enqueueSnackbar(res.msg || "Error", { variant: "error" });
        // for (let [key, value] of formData.entries()) {
        //   console.log(`${key}: ${value}`);
        // }
    }

    function validateDomain(val) {
      return val?.toLowerCase()?.replace('https://','')?.replace('http://')?.replace('www.','')?.replace(/[^\w.]/g,'')
    }

    const agencyLink = (data?.agencyURL || data?.agencyName+".mytravx.com");
    
    const DomainDisplay = (props) => {
      return (
        <div className='inline-block'>
          <CopyText>
            <input type='text' className='font-bold text-blue-700' style={{width: (props.value?.length||10)*9}} value={props.value} />
          </CopyText>
        </div>
      )
    }
    
  return (
    <div className='flex flex-col gap-6'>
        <div>
            <h5>Custom Domain</h5>
            <p>Your service is always available at <a href={'https://'+agencyLink} className='text-blue-600 underline' target='_blank'>{agencyLink}</a></p>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div>
              <TextInput
                value={data.customDomain}
                onChange={(ev) =>
                  setData({ ...data, customDomain: validateDomain(ev.target.value||'')})
                }
                label={"Custom Domain"}
                placeholder={"agency.com"}
              />
              <div className="tooltip">
                This is a unique identifier. It must contain only
                URL-friendly characters.
              </div>
            </div>

          <div className="flex sm:justify-end">
            <Button1
                loading={loading}
                type="submit"
                className="!self-end !text-base sm:!w-auto !px-4 !capitalize"
            >
                Save Changes
            </Button1>
            </div>

        </form>
        <div className='relative flex flex-col gap-2 p-4 pl-0 self-start w-full border-primary/20'>
          {user?.detail?.customDomain ? 
            <div className='flex flex-col gap-2'>
              <div className='flex gap-4 items-center  flex-1'>
                  {user?.detail?.customDomain}
                  <a href={'http://'+user?.detail?.customDomain} rel='noreferrer' target='_blank' 
                    className=''>
                      <Icon icon='iconoir:open-new-window' className='p-1 text-blue-700' />
                  </a>
              </div>
              <div className='flex flex-col gap-2'>
                Steps to redirect to our Domain
                <div>
                  1. Add 
                  <span className='bg-primary/10 px-1 mx-1'>ANAME</span> or 
                  <span className='bg-primary/10 px-1 mx-1'>ALIAS</span>
                  record pointing to <DomainDisplay value={data?.agencyName+".customer.mytravx.com"} />
                  <div className='p-2'>
                      If your DNS Provider does not support 
                    <span className='bg-primary/10 px-1 mx-1'>ANAME</span> or 
                    <span className='bg-primary/10 px-1 mx-1'>ALIAS</span>
                    records, use an
                    <span className='bg-primary/10 px-1 mx-1'>A</span>
                    record pointing to <DomainDisplay value={"34.120.183.228"} />
                  </div>
                </div>
                <div>
                  2. Add a 
                  <span className='bg-primary/10 px-1 mx-1'>CNAME</span>
                  record pointing to <DomainDisplay value={data?.agencyName+".customer.mytravx.com"} />
                </div>
              </div>
            </div>
          :null}

        </div>
    </div>
  )
}
