import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useHotels } from "../../hooks/useHotels";
import { useRef } from "react";
import { URLdecode } from "../../utils/url";
import { useDispatch, useSelector } from "react-redux";
import {
  setHotelConfirmationPdfModal,
  setHotelConfirmationPdfModalEmail,
  setHotelConfirmationPdfModalSendingEmail,
} from "../../redux/reducers/modalSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate } from "../../utils/date";
import { useEffect } from "react";
import { formatMoney } from "../../features/utils/formatMoney";
import { shareHotelBookingPDF } from "../../controllers/Flight/shareHotelBookingPDF";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 600 ? 600 : "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function HotelConfirmationPdfModal() {
  const { travellersString } = useHotels();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { hotelConfirmationPdfModal, hotelConfirmationPdfModalEmail } =
    useSelector((state) => state.modal);
  const { user } = useSelector((state) => state?.user?.userData);

  const divToExportRef = useRef(null);

  const params = URLdecode();
  const rooms = params?.reservedRooms ? JSON.parse(params?.reservedRooms) : [];

  const handleOpen = () => dispatch(setHotelConfirmationPdfModal(false));
  const handleClose = () => dispatch(setHotelConfirmationPdfModal(false));

  useEffect(() => {
    setTimeout(() => {
      if (hotelConfirmationPdfModal) {
        handleExport();
      }
    }, 1000);
  }, [hotelConfirmationPdfModal]);

  const sendPDF = async (pdf) => {
    dispatch(setHotelConfirmationPdfModalSendingEmail(true));
    const formData = new FormData();

    formData?.append("to", hotelConfirmationPdfModalEmail);
    formData.append("subject", "Hotel Booking Details");
    formData.append("body", "Booking details of " + params?.name);
    formData.append("pdf", pdf, params?.bookId + ".pdf");

    enqueueSnackbar("Sending... (This might take a while if the pdf is large)");
    const res = await shareHotelBookingPDF(formData);
    if (res.return) {
      enqueueSnackbar(res?.msg, { variant: "success" });
    } else enqueueSnackbar(res?.msg, { variant: "error" });
    dispatch(setHotelConfirmationPdfModalSendingEmail(false));
  };

  const handleExport = async () => {
    const component = divToExportRef.current;

    if (!component) {
      console.error("Component to export not found");
      return;
    }

    try {
      component.scrollTo(0, component.scrollHeight);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const scrollHeight = component.scrollHeight;

      const canvas = await html2canvas(component, {
        useCORS: true,
        scale: 2,
        windowWidth: document.body.scrollWidth,
        windowHeight: scrollHeight + 500,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
      });

      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [210, scrollHeight * 0.42],
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      let position = 0;
      let pageHeight = pdfHeight;

      const addNewPage = () => {
        pdf.addPage();
        position -= pageHeight;
      };

      for (let imgTop = 0; imgTop < imgHeight; imgTop += pageHeight) {
        if (imgTop + pageHeight > imgHeight) {
          pageHeight = imgHeight - imgTop;
        }
        if (position !== 0) {
          addNewPage();
        }
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, pageHeight);
      }

      if (hotelConfirmationPdfModalEmail) {
        await sendPDF(pdf.output("blob"));
      } else {
        pdf.save(`${params?.bookId}.pdf`);
      }
    } catch (error) {
      console.error("Error capturing screenshot: ", error);
    }

    dispatch(setHotelConfirmationPdfModalEmail(""));
    handleClose();
  };

  const hotelPhone = () => {
    let value = "";
    let phoneNumbers = params?.phone ? JSON.parse(params?.phone) : null;
    if (phoneNumbers) {
      value = phoneNumbers[0]?.phoneNumber;
    }
    return value;
  };

  return (
    <div>
      <Modal
        open={hotelConfirmationPdfModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={Classname.container} ref={divToExportRef}>
            <div className="w-full flex justify-between">
              <div className="flex flex-col">
                <div className="flex items-center gap-2 mb-6">
                  <img
                    src={user?.detail?.agencyLogo}
                    alt=""
                    className="w-7 h-7 object-contain"
                  />
                  <span className="font-bold h-7 flex items-center ">
                    {user?.detail?.agencyName}
                  </span>
                </div>
                <span className="text-sm mb-4">
                  Holder name:{"  "}
                  <strong>
                    {rooms?.at(0)?.guests?.at(0)?.first_name}{" "}
                    {rooms?.at(0)?.guests?.at(0)?.last_name}
                  </strong>
                </span>
              </div>
              <div className="flex flex-col mb-6">
                <span className="flex items-center ">Booking reference</span>
                <span className="font-semibold text-sm flex items-center ">
                  {params?.bookId}
                </span>
              </div>
            </div>
            <div className={Classname.tourDetail}>
              <div className={Classname.tourNameContainer}>
                <div className={Classname.tourDetailContainer}>
                  <span className={Classname.tourName}>{params?.name}</span>
                  <div className={Classname.tourDetailLocationContainer}>
                    <img
                      src="/IconLocationGreen.svg"
                      alt=""
                      className={Classname.tourDetailLocationIcon}
                    />
                    <span>{params?.address}</span>
                  </div>
                  {hotelPhone() && (
                    <div
                      className={`${Classname.tourDetailLocationContainer} mt-2`}
                    >
                      <img
                        src="/IconPhone.svg"
                        alt=""
                        className={"w-4 object-contain ml-1"}
                      />
                      <span>{hotelPhone()}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className={Classname.dateContainer}>
                <div className={Classname.dateFigureContainer}>
                  <div className={Classname.datePoint}></div>
                  <div className={Classname.dateLine}></div>
                  <div className={Classname.datePoint}></div>
                </div>
                <div className={Classname.dateDetailContainer}>
                  <div className={Classname.dateDetailDate}>
                    Check-in date
                    <span className="font-bold">
                      {formatDate(params?.start_date)}
                    </span>{" "}
                  </div>
                  <div className={Classname.dateDetailDuration}>
                    Duration: {params?.nights} nights
                  </div>
                  <div className={Classname.dateDetailDate}>
                    Check-out date
                    <span className="font-bold">
                      {formatDate(params?.end_date)}
                    </span>{" "}
                  </div>
                </div>
              </div>
              <div className={Classname.tagsContainer}>
                <div className={Classname.tag}>
                  <img
                    src="/tourIconPerson.svg"
                    alt=""
                    className={Classname.tagIcon}
                  />
                  <span className="h-4 flex items-center">
                    {params?.travelerMix}
                  </span>
                </div>
              </div>

              {params?.accommodationType && (
                <span className="font-bold text-xs mt-6">
                  Accommodation type: {params?.accommodationType}
                </span>
              )}
              {params?.categoryType && (
                <span className="font-bold text-xs mt-6">
                  Category type: {params?.categoryType}
                </span>
              )}
              <span className="font-bold text-lg mb-2 mt-4">Guest details</span>
              {rooms?.map((room, index) => (
                <div key={index} className="w-full flex flex-col">
                  <div
                    className={`${Classname.tourNameContainer} p-4 bg-gray-100 mt-10 mb-4 rounded-lg`}
                  >
                    <div className={Classname.tourDetailContainer}>
                      <span className="font-bold text-sm mb-1">
                        {/* {index === 0 && "Holder name: "} */}
                        {room?.guests[0]?.title}{" "}
                        {room?.guests[0]?.firstName ||
                          room?.guests[0]?.first_name}{" "}
                        {room?.guests[0]?.lastName ||
                          room?.guests[0]?.last_name}
                      </span>
                      {/* <span className="font-bold text-sm mb-1">Room Info</span> */}
                      <span className="flex font-bold text-gray-600">
                        {room?.name}
                      </span>
                      <p>{room?.description}</p>
                      {room?.rateComments && (
                        <p className="mt-2">{room?.rateComments}</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="w-full p-4 bg-green-200 rounded-md text-xs text-gray-600">
                <strong>Important remarks:</strong> Payable through{" "}
                <strong>
                  {params?.supplier === "HotelBeds"
                    ? params?.hotelSupplier
                    : user?.detail?.agencyName}
                </strong>
                , acting as agent for the service operating company, details of
                which can be provided upon request.
                {params?.vat ? `VAT: ${params?.vat}` : ""} Reference:{" "}
                {params?.bookId}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Classname = {
  container: "flex flex-col font-main max-h-[90vh] overflow-scroll px-10 py-10",
  content: "flex flex-col items-center px-4",
  titleContainer: "w-full text-xl font-bold text-gray-400 my-10",
  titleMain: "text-black",
  detail: "flex flex-col lg:flex-row w-full gap-10",
  tourDetail: "flex flex-col flex-1",
  tourDetailTitleContainer: "flex items-center justify-between w-full gap-8 ",
  tourDetailTitleButtonContainer: "gap-6 ",
  tourDetailTitleExportButton:
    "h-12 px-4 rounded-md border-2 border-gray-300 font-bold mr-3",
  tourDetailTitleManageButton: "h-12 px-4 rounded-md bg-gray-300 font-bold",

  tourNameContainer:
    "flex flex-1 items-center mt-4 lg:mt-0 lg:gap-10 gap-2 pr-2 lg:pr-0",
  tourImageContainer:
    "flex flex-col lg:w-24 items-center gap-2 font-bold text-sm",
  tourImage: "w-full min-w-20 h-20 lg:h-24 object-cover",
  tourDetailContainer: "flex flex-col text-xs lg:text-md gap-3 lg:gap-0",
  tourName: "text-md lg:text-xl  font-bold",
  tourDetailLocationContainer: "gap-2 flex items-center",
  tourDetailLocationIcon: "",

  dateContainer: "flex w-full gap-4 mt-6",
  dateFigureContainer: "flex flex-col items-center py-1",
  datePoint: "w-3 h-3 rounded-full bg-primary1",
  dateLine: "border-r flex flex-1 border-primary1",
  dateDetailContainer: "flex flex-col gap-4",
  dateDetailDate: "flex items-center gap-2 lg:gap-4 text-sm lg:text-md ",
  dateDetailDuration: "text-xs lg:text-sm",

  tagsContainer: "flex items-center flex-wrap gap-4 mt-6",
  tag: "flex gap-2 h-4 items-center text-sm text-gray-500",
  tagIcon: "w-4 h-4 object-contain",

  touristPersonHeader:
    "flex flex-col lg:flex-row w-full justify-around lg:justify-between gap-4",
  touristPerson:
    "text-white bg-primary1 font-bold h-14 px-4 rounded-md flex items-center justify-center gap-2",
};
