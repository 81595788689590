import React, { useEffect, useState } from "react";
import OrderTypeSelection from "../../components/order/OrderTypeSelection";
import OrderFilterTypeSelection from "../../components/order/OrderFilterTypeSelection";
import OrderStatusFilterSelection from "../../components/order/OrderStatusFilterSelection";
import OrderSortHeader from "../../components/order/OrderSortHeader";
import OrderList from "../../components/order/OrderList";
import OrderPagination from "../../components/order/OrderPagination";
import { useDispatch } from "react-redux";
import { setFlightOrders } from "../../redux/reducers/orderSlice";
import { CircularProgress } from "@mui/material";
import getBookings from "../../controllers/booking/getBookings";

function OrdersLoader({setLoading}) {
  useEffect(() => {
    loadData();
  }, []);

  const dispatch = useDispatch();

  const loadData = async () => {
    setLoading(true);
    let promises = [load(), 
      // loadTour(), loadHotel()
    ];
    let responses = await Promise.all(promises);
    setLoading(false);
    responses = responses.flat();
    responses = responses.filter((response) => response);
    if (responses) {
      responses.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      // setData(responses);
    }
  };

  async function load() {
    const res = await getBookings();
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        obj?.flightBooking?.map((flightObj) =>
          data.push({ ...obj, flightObj, type: "Flight" })
        );
        return true;
      });
      dispatch(setFlightOrders(data))
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  // const loadTour = async () => {
  //   let response = await getTourBookings();
  //   response = response?.map((obj) => templateTourOrderData(obj));
  //   return response;
  // };

  // const loadHotel = async () => {
  //   let response = await getHotelBookings();
  //   response = response?.map((obj) => templateHotelOrderData(obj));
  //   return response;
  // };

  return null
}

function NewOrders() {
  const [loading,setLoading] = useState(true);
  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        <OrdersLoader {...{loading,setLoading}} />
        <OrderTypeSelection />
        <OrderFilterTypeSelection />
        <OrderStatusFilterSelection />
        <OrderSortHeader />
        {loading?
          <CircularProgress />
        :
          <OrderList />
        }
        <OrderPagination />
      </div>
    </div>
  );
}

export default NewOrders;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1 pb-96",
};
