import React from 'react'
import { useSelector } from 'react-redux'
import Button1 from '../../components/form/Button1'
import Icon from '../../components/HOC/Icon'
import { def } from '../../config'
import { getTestLevel } from '../../utils/testLevel'
import WhiteLabel from '../Links/WhiteLabel'
import { initTemplateState } from '../../redux/reducers/template/templateSlice'
import { useLocation } from 'react-router-dom'


export default function MyWebsite() {
    const template = useSelector(state => state.template);
    const {subscriptionPlan,user,agent} = useSelector(state => state.user?.userData)
    const subscription = agent?.subscriptionPlan || subscriptionPlan;
    const subscribed = subscription?.isSubscriptionActive
    const ultimate = subscription?.plan?.name === 'Ultimate' && subscribed;
    let siteDomain = 'http://'+user?.detail?.agencyName + '.customer.'+window.location.host;
    let stage = '.'+def.devStatus;
    if(stage === '.live')
        stage = '';
    
    const websiteSetted = JSON.stringify(template)?.toLowerCase() !== JSON.stringify(initTemplateState)?.toLowerCase()

    const location = useLocation();
    const searchParam = new URLSearchParams(location.search);
    const onTry = searchParam.get('try')
    const onLandingPage = !onTry && !websiteSetted;
    
    if(getTestLevel() > getTestLevel('dev') && user?.detail?.agencyURL)
        siteDomain = 'https://'+(user?.detail?.agencyURL||(user?.detail?.agencyName+'.mytravx.com'))?.replace(user?.detail?.agencyName,user?.detail?.agencyName+'.customer'+stage)

  return onLandingPage ? 
    <WhiteLabel />
  : (
    <div className='pd-md '>
        <div className='flex gap-4 justify-between'>
            <div className=''>
                {subscribed && ultimate ? 
                    <h5>My Website</h5>
                :
                    <h5>Start selling travel today using our easy to use tools</h5>
                }
                {subscribed && !ultimate ? 
                    <p>You need to upgrade your current subscription to use this feature</p>
                : !subscribed ?
                    <div>
                        <p>
                            Welcome! To setup your website, you'll need to subscribe to the ultimate plan. Once subscribed, you can start enjoying all the benefits.
                        </p>
                        <p>
                            If you have any questions or need help getting started, our support team is here to assist you.
                        </p>
                        <br />
                        <p>
                            Thank you for choosing us!
                        </p>

                    </div>
                :
                    <p>
                        Manage your site layout here
                    </p>
                }
                {subscribed && ultimate && user?.detail?.agencyName ? 
                    <a href={siteDomain} target='_blank' className='text-blue-500 mt-2 flex gap-2 items-center'>
                        visit website
                        <Icon icon='mdi:open-in-new' className='!w-4 !h-4' />
                    </a>
                :null}
            </div>
            <div className='whitespace-nowrap flex justify-end p-5'>
                <div>
                    {ultimate ? 
                        <div className='flex gap-4'>
                            <Button1 to='/templating' className='!w-auto' >Setup website</Button1>
                            <Button1 variant='outlined' to={`/templating/edit/pages`} className='flex gap-1 !w-auto items-center self-end p-2 rounded-md border'>
                                <Icon icon='mdi:edit' className='!w-4 !h-4' />
                                Edit Pages
                            </Button1>

                        </div>
                    : subscribed ? 
                        <Button1 to='/settings/agency/subscription' >Upgrade</Button1>
                    :
                        <Button1 to='/settings/agency/subscription' >Subscribe</Button1>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
