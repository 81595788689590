import { createSlice } from "@reduxjs/toolkit";

let defaultData = {
  userData: {
    loggedIn: false,
    accessToken: null,
    id: null,
    googleUser: null,
    agent: null,
    user: {
      id: null,
      username: null,
      firstName: "",
    },
    subscriptionPlan: undefined,
  },
};
let initialState = {
  ...defaultData,
};

const UDStorage = window.localStorage.getItem("userData");
if (UDStorage) initialState = { userData: JSON.parse(UDStorage) };

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
      window.localStorage.setItem("userData", JSON.stringify(action.payload));
    },
    setUser: (state, action) => {
      state.userData.user = action.payload;
      window.localStorage.setItem("userData", JSON.stringify(state.userData));
    },
    logout: (state) => {
      state.userData = { ...defaultData.userData, agent: state.userData.agent };
      window.localStorage.setItem("userData", JSON.stringify(state.userData));
    },
  },
});

export const { setUserData, setUser, logout } = userSlice.actions;

export default userSlice.reducer;
