import moment from "moment";
import { formatMoney } from "../../features/utils/formatMoney";
import { getSupplierName } from "../flight/supplier/getSupplierName";
import { getSupplierClass } from "../../components/flight/FlightOfferDisplay";
import { flightStatusMap } from "../../pages/Orders/OrdersData";
import { alertType } from "../constants";
import Icon from "../../components/HOC/Icon";

export function templateFlightOrderData(data) {
    console.log(data);
    const flightData = data?.flightBooking?.at(0);
    // debugger
    let flightType = 'One Way'
    if(flightData?.destinations?.length) {
        flightType = 'Multi City'
        if(flightData?.destinations?.at(-1)?.to === flightData?.destinations?.at(0)?.from)
            flightType = 'Return'
    }
    
    return [
        {
          elements: [
            { type: "title", value: moment(data?.createdAt)?.format('DD-MM-YYYY HH:mm [GMT]Z') },
            { type: "copy", value: flightData?.orderId },
            {
              type: "tag",
              value: getSupplierName(flightData?.supplier),
              className: getSupplierClass(flightData?.supplier),
              color: "text-[#BD002A]",
              background: "bg-[#BD002A]/20",
            },
          ],
          width: "md:w-[25%]",
        },
        {
          elements: [
            { type: "title", value: flightType },
            { type: "text", value: (flightData?.destinations?.slice(0,1)?.map(obj => `${obj?.from} - ${obj?.to}`)?.join(',') || '') + ' '+ flightData?.pnr },
          ],
          width: "md:w-[15%]",
        },
        {
          elements: [
            { type: "title", value: "Travel Time" },
            { type: "text", value: flightData?.flights?.at(0)?.departureDate+ ' ' +flightData?.flights?.at(0)?.departureTime+ ' - ' +flightData?.flights?.at(0)?.arrivalTime },
          ],
          width: "md:w-[15%]",
        },
        {
          elements: [
            { type: "title", value: "Passenger" },
            { type: "text", value: flightData?.travelers?.slice(0,1)?.map(obj => `${obj?.firstName} ${obj?.middleName} ${obj?.lastName}`)?.at(0) },
          ],
          width: "md:w-[15%]",
        },
        {
          elements: [
            { type: "title", value: "Price" },
            { type: "text", value: formatMoney(flightData?.grandTotal) },
          ],
          width: "md:w-[15%]",
        },
        {
          elements: [
            { type: "title", value: "Status" },
            { type: "status", render: () => {
                const status = flightStatusMap(flightData?.status);
                
                return (
                    <div className={`rounded-md mb-3`}>
                    <span
                    className={`${alertType[status?.toLowerCase()]} overflow-hidden text-ellipsis text-xs `}
                    title={status}
                    >
                        {status}
                    </span>
                    </div>
                )
            }},
            { type: "time", render: () => {
                const diffInSeconds = moment.utc(flightData?.ticketingExpiry).diff(moment.utc(), 'seconds');
                
                // Format the difference in the "5h 10m 5s" format
                const hours = Math.floor(diffInSeconds / 3600);
                const minutes = Math.floor((diffInSeconds % 3600) / 60);
                // const seconds = diffInSeconds % 60;
            
                return (hours || minutes) > 0 ? (
                    <div className="flex gap-1 items-center">
                        <Icon icon='mdi:clock-outline' className='!w-4 !h-4' />
                        {hours || minutes} {hours?'Hrs':'Mins'}
                    </div>
                ):null
            }},
          ],
          width: "md:w-[15%]",
        },
      ];
    
}