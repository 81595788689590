import { useSelector } from "react-redux";
import SearchBarList from "../Searchbars/searchBarList";
import TopDestination from "../dashboard/Home/TopDestination";
import Banner from "./Banner";
import HomeCarousel from "./HomeCarousel";
import GuideLinks from "./GuideLinks";
import FooterList from "../footers/footerList";

export function Layout2() {
    const state = useSelector((state)=> state.template);
    const {user,agent} = useSelector(state => state.user.userData)

    const crmData = (agent || user)?.detail?.crmData;
  
    return (
      <div
      className={`w-full h-auto rounded-lg`}
    >
      {/* <div className="bg-white relative origin-top-left top-0">
        <div className="w-full">
            <HeadersList id={state.headerStyle} />
        </div>
      </div> */}
        <div className="bg-[#EBEFF9] flex flex-col gap-10 items-left pb-10 relative">
          <HomeCarousel data={crmData?.hero?.images} />
          <div className="absolute top-0 left-0 w-full h-full bg-black/50"></div>
          <div className="origin-top-left z-10" >
            <div className="font-bold px-6 md:px-20 h-auto text-2xl origin-left mt-20 text-white">
                {!crmData?.hero?.text ? 
                  <span className="flex flex-col gap-2">
                    <span style={{color: state.secondaryColor}}>{'Hello there'},</span>
                    Book cheap flights with your one-step travel shop!
                  </span>
                :
                  <div dangerouslySetInnerHTML={{ __html: crmData?.hero?.text }} />
              }
            </div>
          </div>
          <div className="flex justify-center origin-top-left px-6 md:px-20 z-10">
            <div className="!w-full origin-top-left">
                <SearchBarList/>
            </div>
          </div>
        </div>
      
        <GuideLinks />
        <Banner />
        <div className="px-10 md:px-24 my-10">
          <TopDestination />
        </div>

        <div className="mt-10">
          <FooterList/>
      </div>
    </div>
  
    )
  }
