import React from 'react'
import { Instagram, Facebook, Twitter } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import Icon from '../../HOC/Icon';

export default function Socials({state}) {
    const {user,agent} = useSelector(state => state.user.userData);

    const crmData = (agent || user)?.detail?.crmData;

    const socials = crmData?.contact?.socials;
    
  return (
    <div className="flex gap-3 items-center">
                    
        {socials?.map((obj,i) => {
            const link = obj?.link;
            return (
                <a href={link} key={i} target='_blank' >
                    {obj?.name === 'Instagram' ? 
                        <Instagram style={{color: state.secondaryColor}}/>
                    :
                    obj?.name === 'Facebook' ? 
                        <Facebook  style={{color: state.secondaryColor}}/>
                    :
                    obj?.name === 'X'?
                        <Twitter  style={{color: state.secondaryColor}}/>
                    :
                    obj?.name === 'LinkedIn'?
                        <Icon icon='devicon:linkedin' className='!w-5 !h-5'  style={{color: state.secondaryColor}}/>
                    :null
                    }
                </a>
            )
        })}

    </div>

  )
}
